<template>
  <div>
    <h1 style="font-size: 1.5em"><a-icon type="history" /> Transaction history</h1>
    <br>
    <a-table 
      :data-source="tableData" 
      :columns="columns" 
      :pagination="pagination" 
      :loading="loading.loadTransaction"
      :scroll="{x: 1300, y: tableHeight}"
    >
      <span slot="invoice_type" slot-scope="invoice_type"><i><u>{{ invoice_type }}</u></i></span>
      <a-tag slot="creator" slot-scope="creator" :color="creator == 'admin' ? 'orange' : 'blue'">
        {{ creator.toUpperCase() }}
      </a-tag>
      <span slot="amount" slot-scope="amount" :style="{color: amount > 0 ? 'green' : 'red'}">
        {{ amount > 0 ? `+$${parseFloat((amount).toFixed(5))}` : `-$${parseFloat((amount * -1).toFixed(5))}` }}
      </span>
      <span slot="account_id" class="ellipsis" slot-scope="account_id"><a-button @click="copyValue(account_id)" type="link" size="small" icon="copy" />{{account_id}}</span>
      <span slot="gateway" slot-scope="gateway">
        <a-tag v-if="gateway == 'acb'" style="background: rgba(200, 255, 249, 0.8); color: rgb(31 65 155)">ACB</a-tag>
        <a-tag v-if="gateway == 'binance'" style="background: rgb(24, 26, 32); color: rgb(240 185 11)">Binance</a-tag>
        <a-tag v-if="gateway == 'cryptomus'" style="background: rgb(225 227 233); color: rgb(11 1 19)">Cryptomus</a-tag>
      </span>
      <span slot="type" slot-scope="type">
        <a-tag v-if="type == 'credit'" color="green"><a-icon type="plus-circle" /> Plus</a-tag>
        <a-tag v-if="type == 'debit'" color="red"><a-icon type="minus-circle" /> Minus</a-tag>
      </span>
    </a-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

const columns = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: 80,
  },
  {
    title: 'Created by',
    dataIndex: 'creator',
    key: 'creator',
    scopedSlots: { customRender: 'creator' },
    width: 120,
  },
  {
    title: 'Description',
    dataIndex: 'message',
    key: 'message',
    width: 300
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
    width: 120,
  },
  {
    title: 'Gateway',
    dataIndex: 'gateway',
    key: 'gateway',
    scopedSlots: { customRender: 'gateway' },
    width: 120,
  },
  {
    title: 'Invoice Type',
    dataIndex: 'invoice_type',
    key: 'invoice_type',
    scopedSlots: { customRender: 'invoice_type' }
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: 130,
    scopedSlots: { customRender: 'amount' }
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
  }
];

const pageSize = 10;

export default {
  data() {
    return {
      filter: {
        page: 1,
        page_size: pageSize
      },
      columns,
      loading: {
        loadTransaction: false
      },
      pagination: {
        total: 0,
        pageSize: pageSize,
        current: 1,
        showQuickJumper: false,
        onChange: this.handleTableChange
      },
      lastPage: 1
    }
  },
  async mounted() {
    await this.loadTransaction();
    this.pagination.total = this.tableData.length < pageSize ? this.tableData.length : pageSize + 1;
  },
  computed: {
    ...mapGetters('transaction', {
      transactions: 'getTransactions'
    }),

    tableHeight() {
      return Math.round(innerHeight / 2);
    },

    isLastPage() {
      return this.tableData.length < pageSize;
    },

    tableData() {
      if (!this.transactions) {
        return [];
      }

      if (this.transactions instanceof Array) {
        return this.transactions.map((transaction, index) => {
          transaction.key = transaction._id;
          transaction.no = index + 1;
          transaction.createdAt = new Date(transaction.createdAt).toLocaleString();

          return transaction;
        });
      }

      return [];
    }
  },
  methods: {
    ...mapActions('transaction', {
      getTransactions: 'getTransactions'
    }),

    async loadTransaction() {
      this.loading.loadTransaction = true;
      await this.getTransactions(this.filter);
      this.loading.loadTransaction = false;
    },

    async handleTableChange(page) {
      this.filter.page = page;
      await this.loadTransaction();
      this.pagination.current = page;

      if (page > this.lastPage) {
        this.lastPage = page;
        this.pagination.total = this.isLastPage ? ((page - 1) * pageSize) + this.tableData.length : (page * pageSize) + 1;
      }
    }
  },
}
</script>