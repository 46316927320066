import request from "./base";
const path = "/captcha";

export default {
  getRandomUseragent: function (params) {
    return request().get(path + "/random-useragent", {
      params,
      validateStatus: () => true
    });
  },
};
