import request from "./base";
const path = "/api/deposit";

export default {
  getInfo: function () {
    return request().get(path + "/info");
  },
  createBinanceTransaction: function (params) {
    return request().post(path + "/binance", params);
  },
  createCryptomusTransaction: function (params) {
    return request().post(path + "/cryptomus", params);
  },
  getBinanceTransaction: function () {
    return request().get(path + "/binance");
  },
  createRegisterTransaction: function (params) {
    return request().post(path + "/register", params);
  },
  createRenewTransaction: function (params) {
    return request().post(path + "/renew", params);
  },
};
