<template>
  <div>
    <h1 style="font-size: 1.5em"><a-icon type="history" /> Transaction history</h1>
    <br>
    <div id="filter">
      <a-select v-model="filter.creator" placeholder="Creator" class="mr-10" default-value="" allowClear style="width: 100px">
        <a-select-option value="" disabled>
          Creator
        </a-select-option>
        <a-select-option value="system">
          <a-tag color="blue">System</a-tag>
        </a-select-option>
        <a-select-option value="admin">
          <a-tag color="orange">Admin</a-tag>
        </a-select-option>
      </a-select>
      <a-select v-model="filter.type" placeholder="Type" class="mr-10" default-value="" allowClear style="width: 110px">
        <a-select-option value="" disabled>
          Type
        </a-select-option>
        <a-select-option value="credit">
          <a-tag color="green"><a-icon type="plus-circle" /> Plus</a-tag>
        </a-select-option>
        <a-select-option value="debit">
          <a-tag color="red"><a-icon type="minus-circle" /> Minus</a-tag>
        </a-select-option>
      </a-select>
      <a-select v-model="filter.gateway" placeholder="Gateway" class="mr-10" default-value="" allowClear style="width: 120px">
        <a-select-option value="" disabled>
          Gateway
        </a-select-option>
        <a-select-option value="acb">
          <a-tag style="background: rgba(200, 255, 249, 0.8); color: rgb(31 65 155)">ACB</a-tag>
        </a-select-option>
        <a-select-option value="binance">
          <a-tag style="background: rgb(24, 26, 32); color: rgb(240 185 11)">Binance</a-tag>
        </a-select-option>
        <a-select-option value="cryptomus">
          <a-tag style="background: rgb(225 227 233); color: rgb(11 1 19)">Cryptomus</a-tag>
        </a-select-option>
      </a-select>
      <a-select v-model="filter.invoice_type" placeholder="Invoice Type" class="mr-10" default-value="" allowClear style="width: 170px">
        <a-select-option value="" disabled>
          Invoice Type
        </a-select-option>
        <a-select-option value="register-funcaptcha">
          <i><u>register-funcaptcha</u></i>
        </a-select-option>
        <a-select-option value="renew-funcaptcha">
          <i><u>renew-funcaptcha</u></i>
        </a-select-option>
        <a-select-option value="register-image">
          <i><u>register-image</u></i>
        </a-select-option>
        <a-select-option value="renew-image">
          <i><u>renew-image</u></i>
        </a-select-option>
        <a-select-option value="other">
          <i><u>other</u></i>
        </a-select-option>
      </a-select>
      <a-input class="mr-10" v-model="filter.email" placeholder="Email" style="width: 300px">
        <span slot="addonBefore">Email</span>
      </a-input>
      <a-input class="mr-10" v-model="filter.account_id" placeholder="Account ID" style="width: 300px">
        <span slot="addonBefore">Account ID</span>
      </a-input>
      <a-input class="mr-10" v-model="filter.message" placeholder="Description" style="width: 400px">
        <span slot="addonBefore">Description</span>
      </a-input>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 260px; line-height: 32px">
        <a-input v-model="filter.amount[0]" addonBefore="Amount" style=" width: 57.5%; text-align: center" placeholder="Min" />
        <a-input
          style=" width: 28px; border-left: 0; pointer-events: none; background: #fff"
          placeholder="-"
          disabled
        />
        <a-input v-model="filter.amount[1]" style="width: 32.5%; text-align: center; border-left: 0" placeholder="Max" />
      </a-input-group>
      <span>Created at: </span>
      <a-input-group class="mr-10" compact style="display: inline-block; width: 350px; line-height: 32px">
        <a-range-picker v-model="filter.created_at" />
      </a-input-group>
      <a-button type="primary" @click="loadTransaction"><a-icon type="filter" /> Filter</a-button>
    </div>
    <a-table 
      :data-source="tableData" 
      :columns="columns" 
      :pagination="tablePagination"
      :loading="loading.loadTransaction"
      :scroll="{x: 1400, y: tableHeight}"
    >
      <span slot="invoice_type" slot-scope="invoice_type"><i><u>{{ invoice_type }}</u></i></span>
      <a-tag slot="creator" slot-scope="creator" :color="creator == 'admin' ? 'orange' : 'blue'">
        {{ creator.toUpperCase() }}
      </a-tag>
      <span slot="amount" slot-scope="amount" :style="{color: amount > 0 ? 'green' : 'red'}">
        {{ amount > 0 ? `+$${parseFloat((amount).toFixed(5))}` : `-$${parseFloat((amount * -1).toFixed(5))}` }}
      </span>
      <span slot="account_id" class="ellipsis" slot-scope="account_id"><a-button @click="copyValue(account_id)" type="link" size="small" icon="copy" />{{account_id}}</span>
      <span slot="gateway" slot-scope="gateway">
        <a-tag v-if="gateway == 'acb'" style="background: rgba(200, 255, 249, 0.8); color: rgb(31 65 155)">ACB</a-tag>
        <a-tag v-if="gateway == 'binance'" style="background: rgb(24, 26, 32); color: rgb(240 185 11)">Binance</a-tag>
        <a-tag v-if="gateway == 'cryptomus'" style="background: rgb(225 227 233); color: rgb(11 1 19)">Cryptomus</a-tag>
      </span>
      <span slot="type" slot-scope="type">
        <a-tag v-if="type == 'credit'" color="green"><a-icon type="plus-circle" /> Plus</a-tag>
        <a-tag v-if="type == 'debit'" color="red"><a-icon type="minus-circle" /> Minus</a-tag>
      </span>
    </a-table>
  </div>
</template>

<style scoped>
#filter {
  margin-bottom: 10px;
}

#filter .mr-10 {
  margin-right: 10px;
  margin-bottom: 10px;
}

.ellipsis {
  display: inline-block;
  width: 10ch; /* Limit to 5 characters */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom; /* Align ellipsis properly */
}

/* Mobile Styles */
@media (max-width: 768px) {
  #filter .mr-10 {
    width: 100%!important;
  }
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';

const columns = [
  {
    title: 'No.',
    dataIndex: 'no',
    key: 'no',
    width: 80,
  },
  {
    title: 'Created by',
    dataIndex: 'creator',
    key: 'creator',
    scopedSlots: { customRender: 'creator' },
    width: 120,
  },
  {
    title: 'Description',
    dataIndex: 'message',
    key: 'message',
    width: 300
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    scopedSlots: { customRender: 'type' },
    width: 120,
  },
  {
    title: 'Gateway',
    dataIndex: 'gateway',
    key: 'gateway',
    scopedSlots: { customRender: 'gateway' },
    width: 120,
  },
  {
    title: 'Invoice Type',
    dataIndex: 'invoice_type',
    key: 'invoice_type',
    scopedSlots: { customRender: 'invoice_type' }
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: 130,
    scopedSlots: { customRender: 'amount' }
  },
  {
    title: 'Created at',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Account Id',
    dataIndex: 'account_id',
    key: 'account_id',
    scopedSlots: { customRender: 'account_id' }
  },
];

const pageSize = 10;

export default {
  data() {
    return {
      filter: {
        page: 1,
        page_size: pageSize,
        amount: {
          0: undefined,
          1: undefined
        }
      },
      columns,
      loading: {
        loadTransaction: false
      },
      tablePagination: {
        total: 0,
        pageSize: pageSize,
        current: 1,
        showQuickJumper: true,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
        onChange: this.handleTableChange,
        onShowSizeChange: this.handlePageSizeChange,
        showTotal: (total, range) => {
          return `${range[0]} - ${range[1]} of ${total}`;
        }
      },
    }
  },
  async mounted() {
    this.loadTransaction();
  },
  computed: {
    ...mapGetters('transaction', {
      transactions: 'getTransactions',
      pagination: 'getPagination'
    }),

    tableHeight() {
      return Math.round(innerHeight / 2);
    },

    tableData() {
      if (!this.transactions) {
        return [];
      }

      if (this.transactions instanceof Array) {
        return this.transactions.map((transaction, index) => {
          transaction.key = transaction._id;
          transaction.no = index + 1;
          transaction.createdAt = new Date(transaction.createdAt).toLocaleString();

          return transaction;
        });
      }

      return [];
    },

    optimizedFilter() {
      const filter = {};

      for (let key in this.filter) {
        if (this.filter[key]) {
          if (typeof this.filter[key] == 'string' || typeof this.filter[key] == 'number') {
            filter[key] = this.filter[key];
            continue;
          }

          if (typeof this.filter[key] == 'object' && this.filter[key][0] && this.filter[key][1]) {
            if (key == 'created_at') {
              filter[key] = [
                this.filter[key][0].startOf('day').toISOString(),
                this.filter[key][1].endOf('day').toISOString(),
              ].join();
              continue;
            }

            filter[key] = [this.filter[key][0], this.filter[key][1]].join();
            continue;
          }
        }
      }

      return filter;
    }
  },
  methods: {
    ...mapActions('transaction', {
      getTransactions: 'getTransactionsFromAdmin'
    }),

    async copyValue(value) {
      await navigator.clipboard.writeText(value);
      this.$message.success("Copied");
    },

    async loadTransaction() {
      this.loading.loadTransaction = true;
      
      try {
        await this.getTransactions(this.optimizedFilter);
        this.tablePagination.total = this.pagination.total;
        this.tablePagination.pageSize = this.pagination.pageSize;
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
      }

      this.loading.loadTransaction = false;
    },

    handlePageSizeChange(page, pageSize) {
      this.filter.page = page;
      this.filter.page_size = pageSize;
      this.loadTransaction();
    },

    async handleTableChange(page) {
      this.filter.page = page;
      await this.loadTransaction();
      this.tablePagination.current = page;
    },
  },
}
</script>