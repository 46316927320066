<template>
  <a-card title="Free trial" class="root-card">
    <a-tabs default-active-key="funcaptcha">
      <a-tab-pane key="funcaptcha">
        <span slot="tab"><a-avatar src="/images/funcaptcha.png" style="margin-right: 5px"></a-avatar>Funcaptcha</span>
        <a-row :gutter="[24,8]">
          <a-col :span="isMobile ? 24 : 12">
            <a-form-model layout="vertical" class="funcaptcha-trial-form">
              <a-form-model-item label="Funcaptcha site key">
                <a-select v-model="funcaptcha.sitekey" mode="tags" @change="handleSelectSitekey" size="large" :maxTagCount="1" allowClear placeholder="Choose Funcaptcha site key">
                  <a-select-option key="867D55F2-24FD-4C56-AB6D-589EDAF5E7C5" value="867D55F2-24FD-4C56-AB6D-589EDAF5E7C5">
                    <a-avatar src="/images/twitter.png" style="margin-right: 5px"></a-avatar> X (Twitter) Signup Mobile - <small><i>867D55F2-24FD-4C56-AB6D-589EDAF5E7C5</i></small>
                  </a-select-option>
                  <a-select-option key="2CB16598-CB82-4CF7-B332-5990DB66F3AB" value="2CB16598-CB82-4CF7-B332-5990DB66F3AB">
                    <a-avatar src="/images/twitter.png" style="margin-right: 5px"></a-avatar> X (Twitter) Signup Web - <small><i>2CB16598-CB82-4CF7-B332-5990DB66F3AB</i></small>
                  </a-select-option>
                  <a-select-option key="0152B4EB-D2DC-460A-89A1-629838B529C9" value="0152B4EB-D2DC-460A-89A1-629838B529C9">
                    <a-avatar src="/images/twitter.png" style="margin-right: 5px"></a-avatar> X (Twitter) Unlock - <small><i>0152B4EB-D2DC-460A-89A1-629838B529C9</i></small>
                  </a-select-option>
                  <a-select-option key="C07CAFBC-F76F-4DFD-ABFA-A6B78ADC1F29" value="C07CAFBC-F76F-4DFD-ABFA-A6B78ADC1F29">
                    <a-avatar src="/images/twitter.png" style="margin-right: 5px"></a-avatar> X (Twitter) Appeal Suspended - <small><i>C07CAFBC-F76F-4DFD-ABFA-A6B78ADC1F29</i></small>
                  </a-select-option>
                  <a-select-option key="A2A14B1D-1AF3-C791-9BBC-EE33CC7A0A6F" value="A2A14B1D-1AF3-C791-9BBC-EE33CC7A0A6F">
                    <a-avatar src="/images/roblox.png" style="margin-right: 5px"></a-avatar> Roblox Signup - <small><i>A2A14B1D-1AF3-C791-9BBC-EE33CC7A0A6F</i></small>
                  </a-select-option>
                  <a-select-option key="476068BF-9607-4799-B53D-966BE98E2B81" value="476068BF-9607-4799-B53D-966BE98E2B81">
                    <a-avatar src="/images/roblox.png" style="margin-right: 5px"></a-avatar> Roblox Login - <small><i>476068BF-9607-4799-B53D-966BE98E2B81</i></small>
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="Proxy">
                <a-input v-model="funcaptcha.proxy" placeholder="Enter your proxy like http://proxyuser:proxypass@host:port" style="width: 100%"></a-input>
              </a-form-model-item>
              <a-form-model-item label="UserAgent">
                <a-input v-model="funcaptcha.userAgent" placeholder="Enter your userAgent" style="width: 100%">
                  <a-icon @click="visibles.userAgentSetting = !visibles.userAgentSetting" slot="addonAfter" type="setting" />
                </a-input>
              </a-form-model-item>
              <a-row v-if="visibles.userAgentSetting" :gutter="16">
                <a-col :span="10">
                  <a-form-model-item label="Device">
                    <a-select v-model="randomUserAgent.device" @change="randomUserAgent.browser = undefined">
                      <a-select-option v-for="(device, key) in devices" :key="key" :value="key">
                        <a-avatar :src="device.image" size="small" style="margin-right: 8px" />
                        {{ device.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="10">
                  <a-form-model-item label="Browser">
                    <a-select v-model="randomUserAgent.browser" :disabled="!availableBrowsers || availableBrowsers.length == 0" allowClear placeholder="If you leave it blank, it will be randomly selected">
                      <a-select-option v-for="(browser, key) in availableBrowsers" :key="key" :value="key">
                        <a-avatar :src="browser.image" size="small" style="margin-right: 8px" />
                        {{ browser.title }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="4">
                  <a-form-model-item label="Random UserAgent">
                    <a-button @click="getRandomUserAgent" :loading="loading.getRandomUserAgent" type="default" icon="interaction"></a-button>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-form-model-item label="Blob">
                <a-input v-model="funcaptcha.datablob" placeholder="Enter blob of Funcaptcha challenge generated by your userAgent" style="width: 100%"></a-input>
              </a-form-model-item>
              <google-recaptcha-v2 ref="grecaptcha-component-2" style="margin-bottom: 10px"></google-recaptcha-v2>
              <a-form-model-item>
                <a-button type="primary" @click="solveFuncaptcha" :loading="loading.solveFuncaptcha"><a-icon type="rocket" /> {{loadingText.solveFuncaptcha}}</a-button>
              </a-form-model-item>
            </a-form-model>
          </a-col>
          <a-col :span="isMobile ? 24 : 12">
            <a-form-model layout="vertical">
              <a-form-model-item label="Result">
                <a-textarea v-model="funcaptcha.result" placeholder="Result will be displayed here" :rows="7" :autoSize="{minRows: 7, maxRows: 7}" readonly/>
              </a-form-model-item>
            </a-form-model>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="itt">
        <span slot="tab"><a-avatar src="/images/itt.png" style="margin-right: 5px"></a-avatar>Image To Text</span>
        <a-row :gutter="[24,8]">
          <a-col :span="isMobile ? 24 : 12">
            <a-upload
              class="upload-wd-100"
              accept="image/*"
              list-type="picture-card"
              :file-list="imageToText.fileList"
              :beforeUpload="beforeUploadImage"
              :remove="onRemoveImage"
              @preview="imageToText.preview = true"
              @change="handleChangeImageList"
            >
              <div v-if="imageToText.fileList.length == 0">
                <a-icon type="plus" />
                <div class="ant-upload-text">
                  Upload
                </div>
              </div>
            </a-upload>
            <a-modal v-model="imageToText.preview" :footer="null" @cancel="imageToText.preview = false">
              <img alt="example" style="width: 100%" :src="imageToText.image" />
            </a-modal>
            <google-recaptcha-v2 ref="grecaptcha-component" style="margin-bottom: 10px"></google-recaptcha-v2>
            <a-button type="primary" @click="solveImage" :loading="loading.solveImage"><a-icon type="rocket" /> {{loadingText.solveImage}}</a-button>
          </a-col>
          <a-col :span="isMobile ? 24 : 12">
            <a-textarea v-model="imageToText.result" placeholder="Result will be displayed here" :rows="7" :autoSize="{minRows: 7, maxRows: 7}" readonly/>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
  </a-card>
</template>

<style>
.upload-wd-100 .ant-upload-select-picture-card {
  width: 100%;
  height: 156.6px;
}

.upload-wd-100 .ant-upload-list-item {
  width: 100%;
  height: 156.6px;
}

.upload-wd-100 .ant-upload-list-picture-card-container {
  width: 100%;
  height: 156.6px;
}

.funcaptcha-trial-form .ant-form-item {
  margin-bottom: 0px;
}
</style>

<script>
import GoogleRecaptchaV2 from '../utils/GoogleRecaptchaV2.vue';
import TrialService from '@/services/trial';
import CaptchaService from '@/services/captcha';
import {wait} from '@/utils/common';
import {mapGetters} from 'vuex';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const Browsers = {
  chrome: {
    title: 'Chrome',
    image: '/images/chrome.png'
  },
  edge: {
    title: 'Microsoft Edge',
    image: '/images/edge.png'
  },
  safari: {
    title: 'Safari',
    image: '/images/safari.png'
  },
}

const Devices = {
  android: {
    title: 'Android',
    image: '/images/android.png',
    browsers: {
      chrome: Browsers.chrome,
      edge: Browsers.edge
    }
  },
  iphone: {
    title: 'iPhone',
    image: '/images/iphone.png',
    browsers: {
      chrome: Browsers.chrome,
      safari: Browsers.safari
    }
  },
  ipad: {
    title: 'iPad',
    image: '/images/ipad.png',
    browsers: {
      chrome: Browsers.chrome,
      safari: Browsers.safari
    }
  },
  "x-app-android": {
    title: 'X (Twitter) App in Android',
    image: '/images/twitter.png'
  },
  windows: {
    title: 'Windows',
    image: '/images/windows.png',
    browsers: {
      chrome: Browsers.chrome,
      edge: Browsers.edge
    }
  },
  macos: {
    title: 'MacOS',
    image: '/images/macos.png',
    browsers: {
      chrome: Browsers.chrome,
      edge: Browsers.edge,
      safari: Browsers.safari
    }
  },
};

export default {
  components: {
    GoogleRecaptchaV2
  },
  data() {
    return {
      imageToText: {
        preview: false,
        image: '',
        fileList: [],
        result: undefined
      },
      funcaptcha: {
        sitekey: undefined,
        result: undefined,
        userAgent: undefined,
        proxy: undefined,
        datablob: undefined
      },
      loading: {
        solveImage: false,
        solveFuncaptcha: false,
        getRandomUserAgent: false
      },
      loadingText: {
        solveImage: 'Solve Captcha',
        solveFuncaptcha: 'Solve Captcha'
      },
      visibles: {
        userAgentSetting: false
      },
      devices: Devices,
      browsers: Browsers,
      randomUserAgent: {
        device: 'android',
        browser: 'chrome'
      }
    }
  },
  computed: {
    ...mapGetters("auth", {
      role: "getRole"
    }),

    isAdmin() {
      return this.role == 'admin';
    },

    isMobile() {
      return innerWidth <= 768;
    },

    grecaptchaComponent() {
      return this.$refs['grecaptcha-component'];
    },

    grecaptchaComponent2() {
      return this.$refs['grecaptcha-component-2'];
    },

    availableBrowsers() {
      return this.devices[this.randomUserAgent.device].browsers;
    }
  },
  destroyed() {
    clearInterval(this.loopGetTaskResult);
  },
  mounted() {
    this.getRandomUserAgent();
  },
  methods: {
    handleSelectSitekey(value) {
      this.funcaptcha.sitekey = value.length ? value[value.length - 1] : undefined;
    },
    async getRandomUserAgent() {
      this.loading.getRandomUserAgent = true;
      const {data} = await CaptchaService.getRandomUseragent(this.randomUserAgent);

      const {data: idata, error} = data;

      if (error) {
        this.loading.getRandomUserAgent = false;
        return this.$notification.error({message: error.message});
      }

      this.funcaptcha.userAgent = idata.userAgent;
      this.loading.getRandomUserAgent = false;
    },
    handleChangeImageList({ fileList }) {
      this.fileList = fileList;
    },
    beforeUploadImage(file) {
      getBase64(file).then(image => {
        this.imageToText.image = image;
        this.imageToText.fileList.push({
          uid: file.uid,
          name: file.name,
          status: 'done',
          url: image,
        });
      });
      return false;
    },
    onRemoveImage() {
      this.imageToText.image = '';
      this.imageToText.fileList = [];
    },

    async whileTaskResult(taskId, interval = 3000) {
      const {data} = await TrialService.getTaskResult(taskId);

      if (['SUCCESS', 'ERROR'].includes(data.Status)) {
        return data;
      }

      return new Promise((resolve, reject) => {
        this.loopGetTaskResult = setInterval(async () => {
          try {
            const {data} = await TrialService.getTaskResult(taskId);

            if (['SUCCESS', 'ERROR'].includes(data.Status)) {
              clearInterval(this.loopGetTaskResult);
              return resolve(data);
            }
          }
          catch(error) {
            clearInterval(this.loopGetTaskResult);
            return reject(error);
          }
        }, interval);
      });
    },

    async solveImage() {
      if (!this.imageToText.image) {
        this.$notification.error({message: 'Please choose an image'});
        return;
      }

      let gResponse = '';

      if (!this.isAdmin) {
        if (!this.grecaptchaComponent.rendered) {
          this.grecaptchaComponent.render();
          return;
        }

        gResponse = this.grecaptchaComponent.getResponse();

        if (!gResponse) {
          this.$notification.error({message: 'Please verify the captcha'});
          return;
        }
      }

      this.loading.solveImage = true;
      this.loadingText.solveImage = 'Processing';
      this.imageToText.result = undefined;
      try {
        const {data} = await TrialService.createImageTask({image: this.imageToText.image, recaptcha: gResponse});
        const {TaskId, Code, Message} = data;

        if (Code == 1) {
          throw new Error(Message);
        }

        await wait(500);
        // eslint-disable-next-line no-constant-condition
        const result = await this.whileTaskResult(TaskId);

        if (result.Status == 'SUCCESS') {
          this.imageToText.result = result.Data;
          this.$notification.success({message: 'Solve image captcha success!'});
        } else if (result.Status == 'ERROR') {
          this.imageToText.result = result.Message;
          this.$notification.error({message: `Solve image captcha error: ${result.Message}`});
        }

        if (!this.isAdmin) {
          this.grecaptchaComponent.reset();
        }
      }
      catch(error) {
        if (error?.response?.status == 413 || (error?.name == "AxiosError" && error?.code == "ERR_NETWORK")) {
          this.$notification.error({message: 'Payload too large, please try again'});
        } else {
          const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

          this.$notification.error({message});
          this.imageToText.result = message;

          if (message.includes('Unauthorized')) {
            this.$router.push({name: 'LoginPage'});
          }
        }
        
        if (!this.isAdmin) {
          this.grecaptchaComponent.reset();
        }
      }
      this.loading.solveImage = false;
      this.loadingText.solveImage = 'Solve Captcha';
    },

    async solveFuncaptcha() {
      if (!this.funcaptcha.sitekey) {
        this.$notification.error({message: 'Please choose a Funcaptcha site key'});
        return;
      }

      let gResponse = '';

      if (!this.isAdmin) {
        if (!this.grecaptchaComponent2.rendered) {
          this.grecaptchaComponent2.render();
          return;
        }

        gResponse = this.grecaptchaComponent2.getResponse();

        if (!gResponse) {
          this.$notification.error({message: 'Please verify the captcha'});
          return;
        }
      }

      this.loading.solveFuncaptcha = true;
      this.loadingText.solveFuncaptcha = 'Processing';
      this.funcaptcha.result = undefined;
      try {
        const {data} = await TrialService.createFuncaptchaTask({
          sitekey: this.funcaptcha.sitekey.trim(), 
          recaptcha: gResponse,
          datablob: this.funcaptcha.datablob?.trim(),
          userAgent: this.funcaptcha.userAgent?.trim(),
          proxy: this.funcaptcha.proxy?.trim()
        });
        const {TaskId, Code, Message} = data;

        if (Code == 1) {
          throw new Error(Message);
        }

        await wait(500);
        // eslint-disable-next-line no-constant-condition
        const result = await this.whileTaskResult(TaskId);

        if (result.Status == 'SUCCESS') {
          this.funcaptcha.result = result.Data.Token;
          this.$notification.success({message: 'Solve funcaptcha captcha success!'});
        } else if (result.Status == 'ERROR') {
          this.funcaptcha.result = result.Message;
          this.$notification.error({message: `Solve funcaptcha captcha error: ${result.Message}`});
        }

        if (!this.isAdmin) {
          this.grecaptchaComponent2.reset();
        }
      }
      catch(error) {
        const message = error?.response?.data?.error?.message || error?.response?.data || error.message;

        this.$notification.error({message});
        this.funcaptcha.result = message;

        if (message.includes('Unauthorized')) {
          this.$router.push({name: 'LoginPage'});
        }
        
        if (!this.isAdmin) {
          this.grecaptchaComponent2.reset();
        }
      }
      this.loading.solveFuncaptcha = false;
      this.loadingText.solveFuncaptcha = 'Solve Captcha';
    }
  }
}
</script>